<template>
  <div
    class="variant-item-list__wrapper"
    :class="{
      'opened': !isPopup && show || isPopup,
      'hasCloseBar': !isPopup,
      'loading': isLoading
    }"
  >
    <a
      v-show="!isPopup"
      href="javascript: void(0)"
      @click.prevent="handleCloseVariants"
    >
      <p>&lt; {{ $tv('back', 'cart') }}</p>
    </a>
    <div class="variant-item-list__list">
      <div
        class="loading-indicator__variants"
        v-show="isLoading"
      >
        <VariantAndSuccessorSkeleton />
      </div>
      <div
        v-show="!isLoading"
      >
        <HeadLine :headline="$tv('successorVariantsItem', 'product')" />
        <div
          class="h3"
          v-tv:product="'currentVariant'"
        />
        <VariantItem
          :key="index"
          :item="currentProduct"
          :has-show-alternative-plants="hasShowAlternativePlants"
          :hide-button="true"
          :is-current-variant="true"
          @detail-click="handleDetailClick"
          @close="handleCloseVariants"
          @close-pop-up="handleClosePopup"
        />
      </div>
      <div
        v-show="successorItem && !isLoading"
      >
        <div
          class="h3"
          v-tv:product="'successor'"
        />
        <VariantItem
          :item="successorItem"
          :show-header="false"
          :has-show-alternative-plants="hasShowAlternativePlants"
          :button-text="getSuccessorButtontText"
          :context="context"
          @detail-click="handleReplaceSuccesor"
          @close="handleCloseVariants"
          @close-pop-up="handleClosePopup"
        />
      </div>
      <div
        v-show="!isLoading && variantItems.length > 0"
      >
        <div
          class="h3"
          v-tv:product="'otherVariant'"
        />
        <VariantItem
          v-for="(item, index) in variantItems"
          :key="index"
          :item-no="index"
          :item="item"
          :has-show-alternative-plants="hasShowAlternativePlants"
          :context="context"
          :button-text="getVariantButtontText"
          @detail-click="handleVariantChange"
          @close="handleCloseVariants"
          @close-pop-up="handleClosePopup"
        />
      </div>
    </div>
    <div
      class="notice subline"
      v-show="hasShowAlternativePlants"
    >
      <span v-text="'*'" /><span v-tv:product="'defaultWarehouseNotice'" />
    </div>
  </div>
</template>

<script>
import VariantItem from './singleton/VariantItem.vue'
import HeadLine from './HeadLine.vue'
import {CONFIGURATION_KEYS} from '~/assets/js/constants'
import {PROPERTY_SCOPE_NAMES} from '~/assets/js/constants'
import {usePandaService} from '@/composables/panda_service'
import {useProductService} from '@/composables/product_service'
import {usePlantStore} from '@/store/plants'
import {useUserStore} from '@/store/user'
import {useConfigurationDataStore} from '@/store/configuration-data'
import {storeToRefs} from 'pinia'
import categoryUrlMixin from '~/mixins/category_urls'
import VariantAndSuccessorSkeleton from '~/components/skeletons/VariantAndSuccessorSkeleton.vue'

export default defineComponent({
  mixins: [categoryUrlMixin],
  components: {
    VariantItem,
    HeadLine,
    VariantAndSuccessorSkeleton
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    skuArray: {
      type: Array,
      default: () => []
    },
    chosenQuantity: {
      type: Number,
      default: () => 1
    },
    isPopup: {
      type: Boolean,
      default: true
    },
    context: {
      type: String,
      default()  {
        return 'product'
      }
    }
  },
  setup() {
    const {postPandaBulk} = usePandaService()
    const {getProductsBySkus, getProductBySku, getProductBySkuNew, getProductsBySkusNew} = useProductService()
    const {allPlants} = storeToRefs(usePlantStore())
    const {configurations} = storeToRefs(useConfigurationDataStore())
    const {newCustomer} = storeToRefs(useUserStore())

    return {
      fetchedWarehouses: allPlants,
      postPandaBulk,
      getProductsBySkus,
      getProductsBySkusNew,
      getProductBySku,
      getProductBySkuNew,
      configurations,
      newCustomer
    }
  },
  data() {
    return {
      items: [],
      isLoading: false,
      loadedOnce: false,
      defaultPlant: ''
    }
  },
  computed: {
    getVariantButtontText() {
      return this.context === 'product' ? this.$tv('gotoVariant', 'product') : this.$tv('replaceVariant', 'product')
    },
    getSuccessorButtontText() {
      return this.context === 'product' ? this.$tv('gotoSuccessor', 'product') : this.$tv('replaceSuccessor', 'product')
    },
    hasShowAlternativePlants() {
      const hasShowAlternativePlants = this.configurations?.find(config => config.key === CONFIGURATION_KEYS.SHOW_ALTERNATIVE_PLANTS_KEY)
      return hasShowAlternativePlants?.value === 'True'
    },
    currentProduct() {
      return this.items[0]
    },
    successorItem() {
      return this.items.find(v => v.isSuccessor === true) ?? null
    },
    variantItems() {
      const variants = [...this.items]
      variants.shift() // remove first item, since its the main product
      return variants.filter(v => v.isSuccessor !== true)
    }
  },
  methods: {
    async loadData() {
      if (this.newCustomer !== null || this.skuArray.length < 2) {
        return
      }
      this.isLoading = true
      if (!this.loadedOnce) {
        let products

        products = await this.getProductsBySkusNew(
          this.skuArray,
          this.$globalization.getRegion(),
          this.$i18n.locale,
          PROPERTY_SCOPE_NAMES.VARIANT_ITEMS_NEW)

        let result
        let prices
        const availabilities = []
        if (this.$auth.loggedIn) {
          result = await this.postPandaBulk([...this.skuArray.map(s => ({sku: s?.sku, quantity: this.chosenQuantity}))])
        }

        if (result) {
          prices = result?.map(r =>
            ({
              sku: r?.sku,
              quantity: r?.quantity,
              ...r.pricing
            }))

          for (let item of result) {
            for (let availability of item.availabilities) {
              availabilities.push({
                sku: item.sku,
                quantity: item.quantity,
                ...availability
              })
            }
          }
        }

        const items = []
        for (let i = 0; i < this.skuArray.length; i++) {
          let product = products.find(p => p?.sku.toString() === this.skuArray[i]?.sku.toString())
          if (!product) {
            continue
          }
          const price = prices?.find(s => s?.sku?.toString() === this.skuArray[i]?.sku?.toString())

          const item = {
            currentStock: 0,
            price: price?.calculatedPrice ?? 0,
            ...this.skuArray[i],
            ...product,
            status: product?.salesAreas ? product.salesAreas[0].statusLabel : '',
            stock: availabilities && availabilities.length > 0 ? availabilities.filter(s => s?.sku === product?.sku).map(t => {
              return {
                sku: t?.sku,
                plant: this.$tv(t.plantCode, 'plants'),
                quantity: t.currentStock.quantity,
                unit: t.currentStock.unit,
                isDefault: t.requestId === 'default-plant'
              }
            }) : []
          }
          items.push(item)
        }
        this.items = items
      }
      this.loadedOnce = true
      this.isLoading = false
    },
    handleCloseVariants() {
      this.$emit('close-variants')
    },
    handleClosePopup() {
      this.$emit('close-pop-up', true)
    },
    handleDetailClick(item) {
      let productDesign = this.items.find(p => p.sku === item).productDesign
      let design = this.getCategoryUrlFromTechnicalName(productDesign.toLowerCase())
      navigateTo(this.localePath(
        '/categories/' +
        design +
        '/products/' +
        item))
    },
    handleReplaceSuccesor(item) {
      if (this.context !== 'product') {
        this.$emit('replace-cart-item', item)
      } else {
        this.handleDetailClick(item)
      }
    },
    handleVariantChange(item) {
      if (this.context !== 'product') {
        this.$emit('change-value', item)
      } else {
        this.handleDetailClick(item)
      }
    }
  },
  watch: {
    fetchedWarehouses: {
      immediate: false,
      handler() {
        this.loadedOnce = false
        this.loadData()
      }
    },
    skuArray: {
      immediate: true,
      handler() {
        this.loadedOnce = false
        this.loadData()
      }
    }
  }
})
</script>

<style lang="scss">
.variant-item-list {
  &__wrapper {
    background-color: $color__white;
    opacity: 0;
    position: relative;

    .h3 {
      font-size: 1rem;
      font-weight: $base-text-font-weight-semi-bold;
      margin: 0 0 1rem;
      padding: 0;
      color: $color__primary;
    }

    .h4 {
      font-weight: $base-text-font-weight-light;
      font-size: $base-text-font-size;
      color: $color__text__default;
      margin-bottom: .5rem;
    }

    &.opened {
      margin-bottom: 1.5rem;
      opacity: 1;
    }

    &.hasCloseBar{
      margin-right: 0;
    }

    &.loading {
      min-width: 33rem
    }

    > a {
      font-size: $base-text-font-size;
      padding-left: .625rem;
      display: block;
    }

    .notice {
      margin-top: auto;
      margin-bottom: auto;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    > div {
      width: 100%;
    }
  }
}

.loading-indicator__variants {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 4.625rem;

  .loading-indicator {
    width: 100%;
    height: 100%;
  }
}
</style>
